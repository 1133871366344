import createPlugin from '@@/create-plugin';
import { loadjs } from 'topwrite';
import { useEffect } from 'react';
import useConfig from '@@/use-config';

declare global {
    interface Window {
        _hmt: any[];
    }

}
export default createPlugin({
    activate(context) {
        if (context === 'reader') {
            const siteId = useConfig().getValue('siteid');
            useEffect(() => {
                if (siteId) {
                    loadjs(`https://hm.baidu.com/hm.js?${siteId}`, {
                        async: true,
                    });

                    window._hmt = window._hmt || [];

                    window._hmt.push(['_requirePlugin', 'UrlChangeTracker', {
                        shouldTrackUrlChange: function(newPath: string, oldPath: string) {
                            newPath = newPath.split('?')[0];
                            oldPath = oldPath.split('?')[0];
                            return newPath != oldPath;
                        }
                    }]);
                }
            }, []);
        }
    }
});
